import FormError from './FormError';

export default class FormSender {

  static defaultOptions = {
    classes: {
      invalid:  'form_invalid',
      success:  'form_success',
      sending:  'form_sending',
      submitButton: 'button-submit'
    },
    method: 'post',
    sendingHTML: 'Sending...',
    successHTML: 'Sended',
    error: 'Failed to send message. Please reload the page and try again.'
  }

  #options    = {};
  #form       = null;
  #errors     = [];
  #submit     = null;
  #submitHTML = '';
  #timestamp  = Date.now()

  #eventListeners = {
    'INPUT': function( e ) {
      this.obj.clearError( this.obj.#errors.indexOf( this.error ) );
    }
  };

  constructor( form, url, options = {} ) {
    this.#options    = { ...this.constructor.defaultOptions, ...options };
    this.#form       = form;
    this.#submit     = form.querySelector( `.${ this.#options.classes.submitButton }` );
    this.#submitHTML = this.#submit.innerHTML;
    
    this.#form.addEventListener( 'submit', e => {
      e.preventDefault();
      console.log( 'time', this.#timestamp );
      if( this.#errors.length == 0 ) {
        
        this.#form.classList.add( this.#options.classes.sending );
        this.#submit.innerHTML = this.#options.sendingHTML;

        const formData = new FormData( this.#form );
        formData.append( 't', Date.now() - this.#timestamp );

        fetch( url, { method: this.#options.method, body: formData } )
          .then( response => response.json() )
          .then( data => {
            console.log( 'data', data );

            if( data.error ){
              this.error( data.error );
            } else if( data.success ) {
              this.success( data.success );
            } else if( data.errors ) {
              this.errors( data.errors );
            }
          } )
          .catch( error => {
            console.log( 'error', error );
            this.#submit.innerHTML = this.#submitHTML;
            alert( this.#options.error );
          } ).finally( () => {
            this.#form.classList.remove( this.#options.classes.sending );
          });

      } 

    } );

  }

  success( data ) {
    this.#submit.innerHTML = this.#options.successHTML;
    this.clearErrors();
    this.#form.classList.add( this.#options.classes.success );
    this.#submit.disabled = true;
  }

  error( error ) {
    this.#submit.innerHTML = this.#submitHTML;
    alert( error );
  }

  errors( errors ) {
    this.#submit.innerHTML = this.#submitHTML;
    this.clearErrors();
    this.#submit.disabled = true;
    this.#form.classList.add( this.#options.classes.invalid );
    errors.forEach( error => this.createError( error ) );
  }

  createError( error ) {
    console.log( 'createError' );
    const formError = new FormError( this.#form, error );
    formError.addEventListener( 'input', this.#eventListeners[ 'INPUT' ], { obj : this, error: formError } );
    this.#errors.push( formError );
  }

  clearError( i ) {
    this.#errors[ i ].destroy(); 
    this.#errors.splice( i, 1 );
    if( this.#errors.length == 0 ) {
      this.#form.classList.remove( this.#options.classes.invalid );
      this.#submit.disabled = false;
    }
  }

  clearErrors() {
    this.#errors.forEach( ( error, i ) => this.clearError( i ) );
    this.#errors.length = 0;
  }
  
}